import React, { useState, useEffect, useRef } from 'react'
import { useSelector } from 'react-redux'
import classNames from 'classnames'

import { RemixLoader } from 'remix/src/loader'

import AnalyticsMonitor from 'common/services/AnalyticsMonitor'
import { isSubscriptionExpired } from 'common/utils/permissions'

import styles from './Preview.module.scss'

const remixTemplateUrl = process.env.REACT_APP_REMIX_URL + '/remix.html'

const DEVICES = {
    DESKTOP: 'desktop',
    MOBILE: 'mobile',
}

const PreviewTab = ({ project }) => {
    const { language, organizations, projectSubscription } = useSelector(state => state)
    const [device, setDevice] = useState(DEVICES.DESKTOP)

    const previewRef = useRef(null)
    const remixLoader = useRef(null)

    useEffect(() => {
        AnalyticsMonitor.track('Project preview opened', { projectId: project.id })
        createIframe()
        return () => destroyIframe()
    }, [])

    const createIframe = () => {
        let subscriptionProductCode = organizations.selectedSubscription.product.productCode
        let featureList = organizations.selectedSubscription.product.featureProducts.map(el => el.featureType)

        if (projectSubscription && !projectSubscription.expired) {
            subscriptionProductCode = null
            featureList = projectSubscription.product.featureProducts.map(el => el.featureType)
        }

        remixLoader.current = new RemixLoader({
            mode: 'preview',
            nodeElement: previewRef.current,
            remixUrl: remixTemplateUrl,
            projectId: project.projectId,
            projectNumericId: project.id,
            projectStructure: JSON.stringify(project.projectStructureJson),
            initialWidth: project.projectStructureJson.app.maxWidth,
            lng: language,
            additionalTopOffset: device === DEVICES.DESKTOP ? 102 : 0,
            features: featureList,
            isSubscriptionExpired: isSubscriptionExpired(),
            subscriptionProductCode,
        })
        remixLoader.current.createIframe()
    }
    const destroyIframe = () => {
        if (remixLoader.current) remixLoader.current.destroyIframe()
    }

    const changeDevice = value => {
        destroyIframe()
        setDevice(value)
        setTimeout(() => {
            createIframe()
        }, 0)
    }

    return (
        <div className={styles.preview}>
            <ul className={styles.deviceChanger}>
                <li
                    className={classNames(styles.deviceChangerItem, styles.deviceChangerItemDesktop, {
                        [styles.isActiveDeviceChangerItemDesktop]: device === DEVICES.DESKTOP,
                    })}
                    onClick={() => changeDevice(DEVICES.DESKTOP)}
                />
                <li
                    className={classNames(styles.deviceChangerItem, styles.deviceChangerItemMobile, {
                        [styles.isActiveDeviceChangerItemMobile]: device === DEVICES.MOBILE,
                    })}
                    onClick={() => changeDevice(DEVICES.MOBILE)}
                />
            </ul>
            <div
                className={classNames(styles.iframeWrapper, {
                    [styles.frameWrapperDesktop]: device === DEVICES.DESKTOP,
                    [styles.frameWrapperMobile]: device === DEVICES.MOBILE,
                })}
            >
                <div className={styles.iframeInner}>
                    <div ref={previewRef} />
                </div>
            </div>
        </div>
    )
}

export default PreviewTab
