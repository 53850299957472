import React, { useState, useRef, useMemo, useEffect } from 'react'
import OutsideClickHandler from 'react-outside-click-handler'
import ReactQuill, { Quill } from 'react-quill'

import { getRemixFontsList } from 'common/constants/fonts'

import ErrorLogging from 'common/services/ErrorLogging'

import { isEmail } from "shared/validation"

import 'react-quill/dist/quill.snow.css'

import ColorPicker from './components/ColorPicker/ColorPicker'

import { BASE_COLORS } from './constants'

const Font = Quill.import('formats/font')
const Link = Quill.import("formats/link")
const Parchment = Quill.import('parchment')

// line-height
const lineHeightConfig = {
    scope: Parchment.Scope.INLINE,
    whitelist: ['1', '1.2', '1.5', '1.6', '1.8', '2', '2.4', '2.8'],
}
const lineHeightClass = new Parchment.Attributor.Class('lineheight', 'ql-line-height', lineHeightConfig)
const lineHeightStyle = new Parchment.Attributor.Style('lineheight', 'line-height', lineHeightConfig)
Parchment.register(lineHeightClass)
Parchment.register(lineHeightStyle)
// font-size
const fontSizeConfig = {
    scope: Parchment.Scope.INLINE,
    whitelist: [
        '8px',
        '9px',
        '10px',
        '11px',
        '12px',
        '14px',
        '16px',
        '18px',
        '20px',
        '24px',
        '30px',
        '36px',
        '48px',
        '60px',
        '72px',
        '96px',
    ],
}
const fontSizeClass = new Parchment.Attributor.Class('fontsize', 'ql-font-size', fontSizeConfig)
const fontSizeStyle = new Parchment.Attributor.Style('fontsize', 'font-size', fontSizeConfig)
Parchment.register(fontSizeClass)
Parchment.register(fontSizeStyle)

Font.whitelist = getRemixFontsList().map(f => f.replace(/ /g, ''))
Quill.register(Font, true)

class MyLink extends Link {
    static create(value) {
        const node = Link.create(value)
        value = Link.sanitize(value)
        node.setAttribute('href', value)
        node.removeAttribute('target')
        return node
    }

    format(name, value) {
        super.format(name, value)
        this["domNode"].removeAttribute("target")
    }
}
Quill.register(MyLink)

const toolbar = [
    [{ font: Font.whitelist }, { fontsize: fontSizeConfig.whitelist }],
    [
        'bold',
        'italic',
        'underline',
        { color: [...BASE_COLORS, 'custom-color'] },
        { background: [...BASE_COLORS, 'custom-color'] },
    ],
    [{ align: [] }, { lineheight: lineHeightConfig.whitelist }],
    [{ list: 'ordered' }, { list: 'bullet' }],
]

const formatsToSave = [
    'font',
    'fontsize',
    'bold',
    'italic',
    'underline',
    'color',
    'background',
    'align',
    'lineheight',
    'list',
]

const Text = ({ text, onUpdate, id, propName, isHideLink = false }) => {
    const [isFocused, setIsFocused] = useState(false)
    const [lastFormat, setLastFormat] = useState({})
    const reactQuillRef = useRef()

    const [isShowColorPicker, setIsShowColorPicker] = useState(false)
    const [isShowBackgroundPicker, setIsShowBackgroundPicker] = useState(false)

    useEffect(() => {
        const editor = getEditor()
        if (!editor) return
        const toolbar = editor.getModule('toolbar')
        toolbar.addHandler('color', value => {
            if (value === 'custom-color') {
                setIsShowColorPicker(true)
                return
            }
            editor.format('color', value)
        })
        toolbar.addHandler('background', value => {
            if (value === 'custom-color') {
                setIsShowBackgroundPicker(true)
                return
            }
            editor.format('background', value)
        })
    }, [])

    const onSetColor = (option, value) => {
        const editor = getEditor()
        if (!editor) return
        editor.format(option, value)
    }

    const getEditor = () => {
        if (!reactQuillRef.current) return
        return reactQuillRef.current.getEditor()
    }

    const onFocus = async evt => {
        const editor = getEditor()
        if (!editor) return

        const elem = evt.target
        if (elem.getAttribute('data-mode') !== 'link' && elem.parentElement.getAttribute('data-mode') !== 'link') {
            const lastFormat = editor.getFormat()
            setLastFormat(lastFormat)
        }
        if (!isFocused) {
            setIsFocused(true)
            editor.focus()
        }
    }

    const onBlur = () => setIsFocused(false)

    const handleChange = html => {
        const editor = getEditor()
        if (!editor) return

        // experimental fix for ENGA-2367
        try {
            if (editor.getText().length > 1) {
                const lastFormat = editor.getFormat()
                setLastFormat(lastFormat)
            } else {
                formatsToSave.forEach(format => {
                    const value = lastFormat[format]
                    if (value) editor.format(format, value)
                })
            }
        } catch (err) {
            ErrorLogging.log(err, { issueId: 'ENGA-2367' })
        }

        onUpdate({
            [id]: {
                data: { [propName || 'text']: html },
            },
        })
    }

    const modules = useMemo(
        () => ({
            toolbar: [...toolbar, isHideLink ? ['clean'] : ['link', 'clean']],
        }),
        [isHideLink],
    )

    return (
        <OutsideClickHandler onOutsideClick={onBlur}>
            <div className={`qt${!isFocused ? ' __readonly' : ''}`} onClick={onFocus}>
                <ReactQuill
                    readOnly={!isFocused}
                    modules={modules}
                    defaultValue={text}
                    onChange={handleChange}
                    ref={reactQuillRef}
                />
            </div>

            {isShowColorPicker && (
                <ColorPicker
                    reactQuillRef={reactQuillRef}
                    option="color"
                    onSave={hex => {
                        onSetColor('color', hex)
                        setIsShowColorPicker(false)
                    }}
                    onClose={() => setIsShowColorPicker(false)}
                />
            )}
            {isShowBackgroundPicker && (
                <ColorPicker
                    reactQuillRef={reactQuillRef}
                    option="background"
                    onSave={hex => {
                        onSetColor('background', hex)
                        setIsShowBackgroundPicker(false)
                    }}
                    onClose={() => setIsShowBackgroundPicker(false)}
                />
            )}
        </OutsideClickHandler>
    )
}

export default Text
