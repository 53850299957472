import React, { useEffect, useState, useRef } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import classNames from 'classnames'

import { getFullUrlToSsr } from 'utils/router'

import ImageCropper from 'components/Modal/ImageCropper/ImageCropper'

import iconInfoWarning from 'svg/info_warning.svg'

import { ASSET_MANAGER__ADD_UPLOAD_MEDIA, ASSET_MANAGER__ADD_UPLOAD_MEDIA_INFO } from 'store/actions/asset_manager'

import ConfirmationDialog from '../../Modal/ConfirmationDialog/ConfirmationDialog'

import {
    IMAGE_TYPES,
    UPLOAD_STATUS,
    SUPPORT_IMAGE_TYPES,
    MAX_IMAGE_FILE_SIZE,
    SUPPORT_AUDIO_TYPES,
    MAX_AUDIO_FILE_SIZE,
} from '../utils/constants'
import { mapImageToAssetFormat } from '../utils/utils'

import './UploadMedia.scss'

const UploadMedia = ({
    className = '',
    variant,
    size,
    children,
    isAudio,
    setIsLoading,
    isCompressible,
    onPlace,
}) => {
    const { t } = useTranslation()
    const dispatch = useDispatch()
    const [isPolicyApproved, setIsPolicyApproved] = useState(false)
    const [isOpenPrivacyDialog, setIsOpenPrivacyDialog] = useState(false)
    const [cropperImageFile, setCropperImageFile] = useState(null)
    const [acceptTypes, setAcceptTypes] = useState(() => {
        return isAudio ? Array.from(SUPPORT_AUDIO_TYPES).join(',') : Array.from(SUPPORT_IMAGE_TYPES).join(',')
    })
    const inputRef = useRef()

    useEffect(() => {
        const _acceptTypes = isAudio
            ? Array.from(SUPPORT_AUDIO_TYPES).join(',')
            : Array.from(SUPPORT_IMAGE_TYPES).join(',')
        setAcceptTypes(_acceptTypes)
    }, [isAudio])

    const onChange = async evt => {
        if (!isAudio && evt.target.files.length === 1) {
            const file = evt.target.files[0]
            if (SUPPORT_IMAGE_TYPES.has(file.type)) {
                if (file.type !== IMAGE_TYPES.GIF) {
                    setCropperImageFile(file)
                    inputRef.current.value = null
                } else {
                    try {
                        setIsLoading(true)
                        const fileData = await addUploadMedia(file)
                        onPlace(fileData)
                    } catch (err) {
                        console.error(err)
                    } finally {
                        setIsLoading(false)
                    }
                }
            }
            return
        }

        for (const file of evt.target.files) {
            const isFileLarge = isAudio ? file.size >= MAX_AUDIO_FILE_SIZE : file.size >= MAX_IMAGE_FILE_SIZE
            if (isFileLarge) {
                dispatch(
                    ASSET_MANAGER__ADD_UPLOAD_MEDIA_INFO({
                        total: file.size,
                        name: file.name,
                        status: UPLOAD_STATUS.error,
                        message: t('File size is too large'),
                    }),
                )
                continue
            }
            if (SUPPORT_IMAGE_TYPES.has(file.type) || SUPPORT_AUDIO_TYPES.has(file.type)) {
                addUploadMedia(file)
            }
        }
    }

    const addUploadMedia = async (file, name) => {
        const response = await dispatch(
            ASSET_MANAGER__ADD_UPLOAD_MEDIA({
                file,
                name,
                uploadRequestParams: { isCompressible },
            }),
        )

        return mapImageToAssetFormat(response)
    }

    const onInputClick = evt => {
        if (isAudio && !isPolicyApproved) {
            evt.preventDefault()
            setIsOpenPrivacyDialog(true)
        }
    }

    const onDialogSubmit = async () => {
        await setIsPolicyApproved(true)
        await setIsOpenPrivacyDialog(false)
        inputRef.current.click()
    }

    return (
        <>
            <input
                ref={inputRef}
                className="upload-media"
                type="file"
                id="upload-media"
                onChange={onChange}
                multiple
                accept={acceptTypes}
                onClick={onInputClick}
            />
            <label
                className={classNames('label', className)}
                htmlFor="upload-media"
                data-variant={variant}
                data-size={size}
            >
                {children}
            </label>

            {isOpenPrivacyDialog && (
                <ConfirmationDialog
                    className="upload-media__dialog"
                    onClose={() => setIsOpenPrivacyDialog(false)}
                    onAction={onDialogSubmit}
                    data={{
                        headText: t('Important note!'),
                        image: iconInfoWarning,
                        noteText: (
                            <>
                                {t('Upload only your own audio recordings.')}
                                <br />
                                <Trans
                                    i18nKey="It is forbidden to upload files that violate the copyrights of third parties or the <tag>Service Policy</tag> or local law."
                                    components={{
                                        tag: (
                                            <a
                                                href={getFullUrlToSsr('/terms-of-use')}
                                                target="_blank"
                                                style={{ color: '#69b1fc', textDecoration: 'none' }}
                                            />
                                        ),
                                    }}
                                />
                            </>
                        ),
                        checkboxText: t('I understand that I am solely responsible for the uploaded files'),
                        actionVariant: 'primary',
                        actionText: t('Upload'),
                        cancelText: t('Cancel'),
                    }}
                />
            )}

            {!!cropperImageFile && (
                <ImageCropper
                    file={cropperImageFile}
                    setIsLoading={setIsLoading}
                    isCompressible={isCompressible}
                    onCancel={() => setCropperImageFile(null)}
                    onPlace={image => {
                        onPlace(image)
                        setCropperImageFile(null)
                    }}
                />
            )}
        </>
    )
}

export default UploadMedia
